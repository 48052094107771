import { ModelManager } from '@adobe/aem-spa-page-model-manager';
import { CustomModelClient } from '@marriott/mi-headless-utils';

import '@marriott/global-styles/dist/marriot.global.css';
import { defineComponentMapping } from '../import-components';
import { canUseDOM } from '@marriott/mi-ui-library';
import path from 'path';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import '@glidejs/glide/src/assets/sass/glide.core.scss';
import '@glidejs/glide/src/assets/sass/glide.theme.scss';

path.resolve('./next.config.js');

declare global {
  interface Window {
    jQuery: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $: any;
    deployedEnvType: string;
  }
}

if (canUseDOM) window.$ = window.jQuery = require('jquery-slim/dist/jquery.slim.min.js');

const modelClient = new CustomModelClient(process.env.NEXT_PUBLIC_AEM_HOST);

if (process.env.NODE_ENV !== 'test') {
  ModelManager.initializeAsync({
    modelClient,
  });
}

const App = function (props) {
  const { Component, pageProps } = props;
  const { model, resolvedUrl, isAuthorMode, apolloEnvVars = {} } = pageProps;

  const setEnvVars = useClientEnvVarsStore(state => state.setEnvVars);
  setEnvVars(apolloEnvVars);

  const allowedComponents = model?.cqItems?.root?.[':items']?.responsivegrid?.allowedComponents?.components;

  defineComponentMapping(allowedComponents, resolvedUrl, isAuthorMode);

  return <Component {...pageProps} />;
};

export default App;
